import axios   from "axios";
import {merge} from "lodash";
const CancelToken = axios.CancelToken;
let source = CancelToken.source();
const apiKey = "vC0bqTDur4bGf9QE0%xamnFP8";
const apiKeyExternal = "hVd%asKKQTWwCMEbe@OgMCNrP";
export default {
  getApikey() {return apiKey;},
  getApikeyExternal() {return apiKeyExternal;},
  axiosCatch(error, callback) {
    if (!axios.isCancel(error) && typeof callback === "function") {
      callback(error)
    }
  },
  getAxiosCancelToken() { return CancelToken },
  setAxiosSourceToken(token) { source = token },
  getParams(datos = []) {
    let session = localStorage;
    return merge({
      token: session.getItem("token"),
      autorId: session.getItem("autorId"),
      apiKey,
    }, datos);
  },
  getPostParams(form) {
    let session = localStorage,
    formData = new FormData();
    formData.append("token", session.getItem("token"));
    formData.append("autorId", session.getItem("autorId"));
    formData.append("apiKey", apiKey);
    
    for (let property in form) {
      /*eslint no-prototype-builtins: "error"*/
      if (Object.prototype.hasOwnProperty.call(property, "archivos")) formData.append("archivos", form[property]);
      formData.append(property, form[property]);
    }
    return formData;
  },
  getFilePostConfig() {return {headers: {'Content-Type': 'multipart/form-data'}}; }
}