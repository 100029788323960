/* globals __ENV */
import Vue from 'vue';
import PermisoService from './Services/PermisoService';

const permisoService = new PermisoService();

export default {
/*****La forma de  armar un permiso **** */
/* nombreProyecto . modulo . accion .  tipo*/

/*************************/
/**PERMISOS PARA USUARIOS**/
/***********************/
USUARIOS_VISUALIZAR : 'BhProyectos.Configuraciones.Usuarios.ListadoVisualizar',
USUARIOS_AGREGAR : 'BhProyectos.Configuraciones.Usuarios.Agregar',
USUARIOS_EDITAR : 'BhProyectos.Configuraciones.Usuarios.Editar',

  existePermiso: async (permiso) => {
    let permisoBol = false;

    await permisoService.existePermiso({permiso: permiso}).then(
      resp => {
        permisoBol = resp;
      }
    );
  }
}
