import Vue                              from "vue";
import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';;
import axios                            from "./Services/Requests/AxiosUtils.js";
import moment                           from "moment";
import decimal                          from "decimal.js";
import accounting                       from "accounting";
import permisos                         from "./permisos";
import cardSuccess                      from "./components/cardSuccess.vue";
import modalSuccess                     from "./components/modalSuccess.vue";
import modal                            from "./components/modal.vue";
import permisosError                    from "./components/permisosError";
import inputMoney                       from "./components/inputMoney";

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== "production";

/*
 |--------------------------------------------------------------------------
 | Registro de componentes globales para la aplicación
 |--------------------------------------------------------------------------|
 */
Vue.component("card-success", cardSuccess);
Vue.component("modal-success", modalSuccess);
Vue.component("modal", modal);
Vue.component("permisos-error", permisosError);
Vue.component("q-input-money", inputMoney);

Object.defineProperties(Vue.prototype, {
    permisos: {get: () => permisos},
    Decimal: {get: () => decimal},
    accounting: {get: () => accounting},
    moment: {get: () => moment},
    dateFormat: {
      get: () => (fecha, formato = 1, showHoras = true) => {
        let date = moment(fecha),
            horas = showHoras ? date.format("HH:mm:ss") : "",
            mesParceado = date.format("MMM").replace(".", ""),
            mes = mesParceado.charAt(0).toUpperCase() + mesParceado.slice(1);
        switch (formato) {
          // yyyy-mm-dd
          case 1: return !fecha ? "--" : `${date.format("YYYY-MM-DD")} ${horas}`;
          // dd/mmm/yy
          case 2: return !fecha ? "--" : `${date.format("DD")}/${mes}/${date.format("YY")}  ${horas}`;
          // dd/mm/yy
          case 3: return !fecha ? "--" : `${date.format("DD/MM/YY")} ${horas}`;
          // dd mmm
          case 4: return !fecha ? "--" : `${date.format("DD")} ${mes} ${horas}`;
          // hoy || ayer || dd mmm
          case 5:
            if (!fecha)
              return "--";
            else if (date.isSame(moment(), "day"))
              return "Hoy";
            else if (date.isSame(moment().subtract(1, "days"), "day"))
              return "Ayer";
            else
              return `${date.format("DD")} ${mes} ${horas}`;
        }
      }
    },
    axiosCancelToken: {get: () => axios.getAxiosCancelToken()},
    axiosSourceToken: {set: (token) => axios.setAxiosSourceToken(token)},
    loader: {
        get: () => (show, mensaje = "", color = "template") => {
            if (show) {
                document.onkeydown = () => {
                    return false;
                };
                Loading.show({
                    spinner: QSpinnerGears,
                    message: mensaje,
                    messageColor: "white",
                    spinnerSize: 80,
                    spinnerColor: color
                });
            } else {
                Loading.hide();
                document.onkeydown = () => {
                    return true;
                };
            }
        }
    },
    alertShow: {
        get: () => (mensaje, tipo = "", position = "top") => {
            let conf = {
                message: mensaje, position,
                timeout: 10000,
                html: true,
                classes: "my-notify",
                actions: [{icon: "close", color: "white"}]
            };

            switch (tipo) {
                case "exito":
                    conf.color = "green-8";
                    break;
                case "error":
                    conf.color = "red-8";
                    break;
                case "warning":
                    conf.color = "amber-8";
                    break;
                default:
                    conf.color = "grey-2";
            }

            Notify.create(conf);
        }
    },
    dateValidate: {
        get: () => (fecha, tipo = 1, validarFechaFutura = true, validarAnioMinimo = true, validarFechaPasado = false, minAnio = 2019 ) => {
          // Valida que no este vacío el campo
          if (!fecha) return 'Fecha es requerida';
    
          // Valida que la longitud del input sea correcta      
          if (fecha.length != 10) return 'Formato fecha incorrecta';
    
          //// Valida que la fecha sea correcta ////
          let dia  = parseInt(fecha.substring(0,2));
          let mes  = parseInt(fecha.substring(3,5));
          let anio = parseInt(fecha.substring(6,10));
          let esBisiesto;
    
          switch (mes) {
            case 1: case 3: case 5: case 7: case 8: case 10: case 12:
              if(dia < 1 || dia > 31)
                return "Día incorrecto, verificar";
              break;
            case 4: case 6: case 9: case 11:
              if(dia < 1 || dia > 30)
                return "Día incorrecto, verificar";
              break;
            case 2:
              if (((anio % 4 == 0) && (anio % 100 != 0 )) || (anio % 400 == 0))
                esBisiesto = true;
              else 
                esBisiesto = false;
              if(esBisiesto) {
                if(dia < 1 || dia > 29)
                  return "Día incorrecto, verificar";
              }
              else {
                if(dia < 1 || dia > 28)
                  return "Día incorrecto, verificar";
              }
              break;
            default:
              return "Mes incorrecto, verificar"          
          }
    
          // Valida que la fecha no pueda ser superior a fecha actual
          if(validarFechaFutura) {
            if (moment(fecha,'DD/MM/YYYY').format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
              return "La fecha no puede ser superior a fecha actual";
            }
          }
    
          // Valida que la fecha no pueda ser menor a fecha actual
          if(validarFechaPasado) {
            if (moment(fecha,'DD/MM/YYYY').format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
              return "La fecha no puede ser menor a fecha actual";
            }
          }
    
          // Valida que el anio ingresado tenga un mínimo
          if(validarAnioMinimo) {
            if (anio <= minAnio) {
              minAnio++;
              return "El año no puede ser menor al "+minAnio;
            }
          }
    
          let anioActual;
          let diferencia;
          switch(tipo) {
            // Caso default no valida algo mas
            case 1: break;
            // Validar fecha de nacimiento
            case 2:
              anioActual = moment().format('YYYY');
              diferencia = anioActual - anio;
              if(diferencia < 18)
                return "Edad mínima 18 años";
              else if(diferencia >= 80)
                return "Edad máxima 80 años";
              break;        
          }                  
          
          return true;
        }
      },
      getArrayQueryParam: {
        get: () => (param, isArrayNumeric = false) => {
          let filtro = param ? (typeof param === "string" ? param.split(",") : param) : [];
    
          return isArrayNumeric ? filtro.map(Number) : filtro;
        }
      }
});

Vue.prototype.colorStatusBadge = function (status, statusFinal = 200) {
    switch (Number(status)) {
        case 100, 500:
            return 'grey';
        case 101:
            return 'dark';
        case 200:
            return statusFinal === 200 ? 'positive' : 'primary';
        case 201:
            return 'purple-8';
        case 300:
            return 'negative';
        case 301:
            return 'orange-6';
        case 302:
            return 'negative';
        case 400:
            return statusFinal === 200 ? 'primary' : 'positive';
    }
};

/*
 |--------------------------------------------------------------------------
 | Registro de directivas
 |--------------------------------------------------------------------------
 */
Vue.directive("ingresar", {
    bind(el, binding) {
        el.addEventListener("keydown", e => {
            //Se omiten las teclas del, espacio, tab, esc y enter
            let special = [46, 8, 9, 27, 13];
            if (binding.modifiers["decimales"]) {
                // Si existe teclado numerico agregamos sus teclas
                special.push(110, 190);
            }
            // Aceptamos combinaciones de teclas basicas sobre inputs
            if (
                special.indexOf(e.keyCode) !== -1 ||
                // Ctrl+A
                (e.keyCode === 65 && e.ctrlKey === true) ||
                // Ctrl+C
                (e.keyCode === 67 && e.ctrlKey === true) ||
                // Ctrl+X
                (e.keyCode === 88 && e.ctrlKey === true) ||
                // home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39)
            ) {
                return;
            }
            if (
                binding.modifiers["letras"] &&
                // Solo letras
                e.keyCode >= 65 &&
                e.keyCode <= 90
            ) {
                return;
            }
            if (
                binding.modifiers["numeros"] &&
                // Solo numeros enteros
                ((!e.shiftKey && e.keyCode >= 48 && e.keyCode <= 57) ||
                    // Si existe teclado numerico agregamos sus teclas
                    (e.keyCode >= 96 && e.keyCode <= 105))
            ) {
                return;
            }
            e.preventDefault();
        });
    }
});

export default Vue;