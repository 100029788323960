import ValidarPermisoService from '../Services/ValidarPermisoService';
import permisos from '../permisos';

export default [
  { path: "/login", component: () => import("../views/login"), name: "login" },
  {
    path: "/configuraciones/",
    component: () => import("../components/layouts/TemplateConfiguraciones"),
    //beforeEnter: (_,__, next) => ValidarPermisoService.validarAccesoRuta(permisos.CONFIGURACIONES_TEMPLATE_ENTRAR, next),
    children: [
      { path: "home", component: () => import("../views/homeConfiguraciones.vue"), name: "homeConfiguraciones", meta:{ requiresAuth : true}},
      //Perfiles
      {path:"perfiles", component:()=> import("../views/perfiles/perfilesGestor"), name: "perfilGestor", meta:{requiresAuth: true}},
      {path:"perfil/detalle/:id", component:()=> import("../views/perfiles/perfilesDetalle"), name: "perfilDetalle", meta:{requiresAuth: true}},
      {path:"perfil/editarPermisos/:id", component:()=> import("../views/perfiles/perfilesEditarPermisos"), name: "perfilEditarPermisos", meta:{requiresAuth: true}},
    ]
  },
  {
    path: "/",
    component: () => import("../components/layouts/Template"),
    children: [
      { path: "home", component: () => import("../views/home"), name: "home"},
      //Usuarios
      { path:"usuarios", component: () => import("../views/usuarios/usuariosGestor.vue"), name: "usuariosGestor", meta:{ requiresAuth : true},
          //beforeEnter: (to, from, next) => ValidarPermisoService.validarAccesoRuta(permisos.USUARIOS_VISUALIZAR, next) 
      },
      { path:"usuarios/agregar", component: () => import("../views/usuarios/usuariosAgregar.vue"), name: "usuarioAgregar", meta:{ requiresAuth : true}},
      { path:"usuarios/editar/:id", component: () => import("../views/usuarios/usuariosEditar.vue"), name: "usuarioEditar", meta:{ requiresAuth : true}},
    ]
  }
];

